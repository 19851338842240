<template>
  <b-navbar id="navbarauth-v3">
    <b-navbar-brand href="https://jaybod.com">
      <div class="margin-left-logo-v3">
        <img crossorigin="anonymous" :src="this.$logo"  alt class="cursor-pointer px-2 img-fluid logo-jaybod-v3" />
        <!-- <img src="@/assets/images/Logo.svg"  alt class="cursor-pointer px-2 img-fluid" /> -->
      </div>
    </b-navbar-brand>
  </b-navbar>
</template>

<script>
export default {
  name: 'NavLoginRegister'
}
</script>

<style>

</style>