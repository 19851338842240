<script>
import appConfig from "@/app.config";
import axios from 'axios'

export default {
  name: "app",
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === "function" ? title(this.$store) : title;
      return title ? `${title} | ${appConfig.title}` : appConfig.title;
    }
  },
  async beforeMount() {
    await axios.get(`${process.env.VUE_APP_API}setting`)
    .then((res) => {
      var isMaintenance = res.data.data.ismaintance
      // var isMaintenance = 'false'
      // var isMaintenance = 'true'
      if(isMaintenance == "true"){
        this.$router.push('/maintenance')
      } else {
        if(this.$route.name == 'Login' || this.$route.name == 'home' || this.$route.name === 'maintenance') {
          this.$router.push('/').catch(()=>{})
        }
      }
    })
    .catch((err) => {
    })
  }
};
</script>

<template>
  <div id="app">
    <!-- <RouterView :key="$route.fullPath"/> -->
    <router-view :key="$route.fullPath"></router-view>

  </div>
</template>
