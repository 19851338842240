import MFA from '../../api/mfa'
const mfa = new MFA()

export const state = {
    dataMFA: null,
    loading: false,
    backupCode: [],
    emailAuth: localStorage.getItem('authUser') ? JSON.parse(localStorage.getItem('authUser'))[0].email : "",
    secretAuth: localStorage.getItem('authUser') ? JSON.parse(localStorage.getItem('authUser'))[0].secret : ""
}

export const getters = {
    dataMFA(state){
        return state.dataMFA
    },
    backupCode(state){
        return state.backupCode
    },
    emailAuth(state){
        return state.emailAuth
    },
    secretAuth(state){
        return state.secretAuth
    },
    loading(state){
        return state.loading
    }
}

export const mutations = {
    SET_LOADING(state, value){
        state.loading = value
    },
    SET_DATA_MFA(state, value){
        state.dataMFA = value
    },
    SET_BACKUP_CODE(state, value){
        state.backupCode = value
    }
}

export const actions = {
    enableDisable2fa(context, data){
        context.commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        return new Promise((resolve, reject) => {
            mfa.enabledDisabled2fa(data)
            .then((result)=>{
                context.commit("SET_LOADING",false);
                
                context.commit("SET_BACKUP_CODE", result.data.backupcode);
                context.commit("SET_DATA_MFA", result.data);
                resolve(result)
            })
            .catch((err)=>{
                context.commit("SET_LOADING",false);
                context.commit("error/SET_MESSAGE",err,{root:true});
                context.commit("error/SET_ERROR",true,{root:true});
                reject(err);
            })
        })
    },
    verifyGoogleAuth(context, data){
        context.commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        return new Promise((resolve, reject) => {
            mfa.verifyCode2fa(data)
            .then((res)=>{
                var result = [res.data]
                context.commit("SET_LOADING",false);
                context.commit("auth/SET_CURRENT_USER", result, {root:true})
                context.commit("user/SET_FIRST_NAME",result[0].firstname,{ root: true });
                context.commit("user/SET_LAST_NAME",result[0].lastname,{ root: true });
                context.commit("user/SET_EMAIL",result[0].email,{ root: true });
                context.commit("user/SET_DATE_JOIN",result[0].date_join,{ root: true });
                context.commit("user/SET_SIZE",result[0].size_directory,{ root: true });
                context.commit("user/SET_USED",result[0].total_used_directory,{ root: true });
                context.commit("user/SET_ID",result[0]._id,{ root: true });
                context.commit("user/SET_PACKAGE",result[0].package,{ root: true })
                resolve(result)
            })
            .catch((err)=>{
                context.commit("SET_LOADING",false);
                context.commit("error/SET_MESSAGE",err,{root:true});
                context.commit("error/SET_ERROR",true,{root:true});
                reject(err);
            })
        })
    },
    verifyBackupCode(context, data){
        context.commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        return new Promise((resolve, reject) => {
            mfa.verifyBackupCode2fa(data)
            .then((res)=>{
                var result = [res.data]
                context.commit("SET_LOADING",false);
                context.commit("auth/SET_CURRENT_USER", result, {root:true})
                context.commit("user/SET_FIRST_NAME",result[0].firstname,{ root: true });
                context.commit("user/SET_LAST_NAME",result[0].lastname,{ root: true });
                context.commit("user/SET_EMAIL",result[0].email,{ root: true });
                context.commit("user/SET_DATE_JOIN",result[0].date_join,{ root: true });
                context.commit("user/SET_SIZE",result[0].size_directory,{ root: true });
                context.commit("user/SET_USED",result[0].total_used_directory,{ root: true });
                context.commit("user/SET_ID",result[0]._id,{ root: true });
                context.commit("user/SET_PACKAGE",result[0].package,{ root: true })
                resolve(result)
            })
            .catch((err)=>{
                context.commit("SET_LOADING",false);
                context.commit("error/SET_MESSAGE",err,{root:true});
                context.commit("error/SET_ERROR",true,{root:true});
                reject(err);
            })
        })
    },
    regenerateCode(context){
        context.commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        return new Promise((resolve, reject) => {
            mfa.regenerateCode2fa()
            .then((result)=>{
                context.commit("SET_BACKUP_CODE", result.data)
                context.commit("SET_LOADING",false);
                resolve(result)
            })
            .catch((err)=>{
                context.commit("SET_LOADING",false);
                context.commit("error/SET_MESSAGE",err,{root:true});
                context.commit("error/SET_ERROR",true,{root:true});
                reject(err);
            })
        })
    },
    downloadBackup(context){
        context.commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        return new Promise((resolve, reject) => {
            mfa.downloadBackupCode2fa()
            .then((result)=>{
                context.commit("SET_LOADING",false);
                resolve(result)
            })
            .catch((err)=>{
                context.commit("SET_LOADING",false);
                context.commit("error/SET_MESSAGE",err,{root:true});
                context.commit("error/SET_ERROR",true,{root:true});
                reject(err);
            })
        })
    },
    viewBackupCode(context, data){
        context.commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        return new Promise((resolve, reject) => {
            mfa.viewBackupCode2fa(data)
            .then((result)=>{
                context.commit("SET_LOADING",false);
                
                context.commit("SET_BACKUP_CODE", result.data);
                resolve(result)
            })
            .catch((err)=>{
                context.commit("SET_LOADING",false);
                context.commit("error/SET_MESSAGE",err,{root:true});
                context.commit("error/SET_ERROR",true,{root:true});
                reject(err);
            })
        })
    }
}