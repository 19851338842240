export const state = {
    message : "",
    success : false,
    error : false
}

export const mutations = {
    SET_MESSAGE(state, newValue) {
        state.message = newValue
    },
    SET_SUCCESS(state, newValue) {
        state.success = newValue
    },
    SET_ERROR(state, newValue) {
        state.error = newValue
    },
}

export const getters = {
    message(state) {
        return state.message
    },
    success(state) {
        return state.success
    },
    error(state) {
        return state.error
    },
}

