const axios = require('axios')
import Client from "../api/client";

export default class Enterprise extends Client {
    constructor() {
        super();
    }
    listEnterprise(user_id){
        return new Promise(function(resolve,reject){
            this.globalGet(`packages/lists/enterprise?user_id=${user_id}`)
            .then((result)=>{
                if(result.status == 200){
                    resolve(result.data);
                }else{
                    reject(result.display_message)
                }
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    myEnterprise(){
        return new Promise(function(resolve,reject){
            this.get('enterprise/get_my_enterprise')
            .then((result)=>{
                if(result.status == 200){
                    resolve(result.data);
                }else{
                    reject(result.display_message)
                }
            })
            .catch(err=> {
                reject(err)
            });
        }.bind(this))
    }
    overviewEnterprise(){
        return new Promise(function(resolve,reject){
            this.get('enterprise/enterprise_dashboard')
            .then((result)=>{
                if(result.status == 200){
                    resolve(result.data);
                }else{
                    reject(result.display_message)
                }
            })
            .catch(err=> {
                reject(err)
            });
        }.bind(this))
    }
    seatEnterprise(payload){
        return new Promise(function(resolve,reject){
            this.get(`enterprise/list_members?enterprise=${payload.enterprise}&used=${payload.used}&search=${payload.search}`)
            .then((result)=>{
                if(result.status == 200){
                    resolve(result.data);
                }else{
                    reject(result.display_message)
                }
            })
            .catch(err=> {
                reject(err)
            });
        }.bind(this))
    }
    subdomainEnterprise(value){
        return new Promise(function(resolve,reject){
            this.post('enterprise/subdomain_checking', value)
            .then((result)=>{
                if(result.status == 200){
                    resolve(result.data);
                }else{
                    reject(result.display_message)
                }
            })
            .catch(err=> {
                reject(err)
            });
        }.bind(this))
    }
    planEnterprise(id){
        return new Promise(function(resolve,reject){
            this.get(`enterprise/plan?enterprise=${id}`)
            .then((result)=>{
                if(result.status == 200){
                    resolve(result.data);
                }else{
                    reject(result.display_message)
                }
            })
            .catch(err=> {
                reject(err)
            });
        }.bind(this))
    }
    setSettings(data){
        return new Promise(function(resolve,reject){
            this.post(`enterprise/set_setting_enterprise`, data)
            .then((result)=>{
                if(result.status == 200){
                    resolve(result.data);
                }else{
                    reject(result.display_message)
                }
            })
            .catch(err=> {
                reject(err)
            });
        }.bind(this))
    }
    previewImg(data){
        return new Promise(function(resolve,reject){
            this.post(`enterprise/generate_assets_upload`, data.payloadImg)
            .then((result)=>{
                let config = {
                    method: 'put',
                    maxBodyLength: Infinity,
                    url: result.data.link,
                    headers: { 
                      'x-amz-acl': 'public-read', 
                      'Content-Type': data.payloadImg.content_type
                    },
                    data : data.file
                };
                axios.request(config)
                .then((response) => {
                })
                .catch((error) => {
                });
                resolve(result.data.link)
            })
            .catch(err=> {
                reject(err)
            });
        }.bind(this))
    }
    inviteSeat(data){
        return new Promise(function(resolve,reject){
            this.post(`enterprise/invite_member`, data)
            .then((result)=>{
                if(result.status == 200){
                    resolve(result.data);
                }else{
                    reject(result.display_message)
                }
            })
            .catch(err=> {
                reject(err)
            });
        }.bind(this))
    }
    seatEdit(data){
        return new Promise(function(resolve,reject){
            this.post(`enterprise/edit_member`, data)
            .then((result)=>{
                if(result.status == 200){
                    resolve(result.data);
                }else{
                    reject(result.display_message)
                }
            })
            .catch(err=> {
                reject(err)
            });
        }.bind(this))
    }
    deleteSeat(data){
        return new Promise(function(resolve,reject){
            this.delete(`enterprise/remove_member`, data)
            .then((result)=>{
                if(result.status == 200){
                    resolve(result.data);
                }else{
                    reject(result.display_message)
                }
            })
            .catch(err=> {
                reject(err)
            });
        }.bind(this))
    }
    generateToken(data){
        return new Promise(function(resolve,reject){
            this.post(`enterprise/generate_token`, data)
            .then((result)=>{
                if(result.status == 200){
                    var token = result.data.token
                    window.open(`${data.link}enterprise/validate?token=${token}`, "_blank")
                    resolve(result.data);
                }else{
                    reject(result.display_message)
                }
            })
            .catch(err=> {
                reject(err)
            });
        }.bind(this))
    }
    validateToken(data){
        return new Promise(function(resolve,reject){
            this.postLogin(`enterprise/public/verify`, data)
            .then((result)=>{
                if(result.status == 200){
                    // var token = result.data.token
                    // window.open(`${data.link}?token=${token}`, "_blank")
                    resolve(result.data);
                }else{
                    reject(result.display_message)
                }
            })
            .catch(err=> {
                reject(err)
            });
        }.bind(this))
    }
    appSettings(data){
        return new Promise(function(resolve,reject){
            this.globalGet(`enterprise/public/setting?domain=${data.domain}`)
            .then((result)=>{
                if(result.status == 200){
                    // var token = result.data.token
                    // window.open(`${data.link}?token=${token}`, "_blank")
                    resolve(result.data);
                }else{
                    reject(result.display_message)
                }
            })
            .catch(err=> {
                reject(err)
            });
        }.bind(this))
    }
    enterpriseInfo(id){
        return new Promise(function(resolve,reject){
            this.get(`enterprise/enterprise_info?enterprise=${id}`)
            .then((result)=>{
                if(result.status == 200){
                    resolve(result);
                }else{
                    reject(result.display_message)
                }
            })
            .catch(err=> {
                reject(err)
            });
        }.bind(this))
    }
}