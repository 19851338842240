// @ts-nocheck
import Storage from "./../../api/storage";
import * as tus from "tus-js-client";
const storage = new Storage();

// {
//     "_id": "60d3155b7c0134151a9c6c47",
//     "file_name": "dokumen kantor 1",
//     "hash_name": "hhpOFzC0anC5hNNc2h5s",
//     "extension": "",
//     "size": 0,
//     "type": 2,
//     "deleted": false,
//     "deleted_dated": "",
//     "parent_id": "60d315347c0134151a9c6c46",
//     "datecreated": "2021-06-23 18:04:59",
//     "datemodified": "2021-06-23 18:04:59",
//     "directory": "3oOhvbmxSRWY8G3tkB0X",
//     "users_share": [],
//     "nested": []
// }

export const state = {
    limit : 50,
    // limit : 10,
    totalLoad : 10,
    totalItem : 0,
    offset : 0,
    keyword : "",
    parent_id : "",
    items : [],
    item : null,
    loading : false,
    breadcumbs : [],
    hashMove : "",
    showBreadcumb : true,
    subTitle : "Storage",
    recentList : [],
    linkFile : "",
    publicShare : false,
    selected : [],
    showProperties : false,
    gridView : false,
    sideRightToggle : false,
    notifToggle : false,
    newName : '',
    displaySidebar : false, // false display main sidebar, true display second sidebar
    linkS3: '',
    canAccessFile: false
}

export const mutations = {
    SET_CAN_ACCESS_FILE(state, newValue){
        state.canAccessFile = newValue
    },
    SET_LINK_FILE_S3(state, newValue) {
        state.linkS3 = newValue
    },
    SET_LIMIT(state, newValue) {
        state.limit = newValue;
    },
    SET_PUBLIC_SHARE(state, newValue) {
        state.publicShare = newValue;
    },
    SET_LINKFILE(state, newValue) {
        state.linkFile = newValue;
    },
    SET_TOTALLOAD(state, newValue) {
        state.totalLoad = newValue;
    },
    SET_TOTALITEM(state, newValue) {
        state.totalItem = newValue;
    },
    SET_RECENTLIST(state, newValue) {
        state.recentList = newValue;
    },
    SET_SHOWBREADCUMB(state, newValue) {
        state.showBreadcumb = newValue;
    },
    SET_SUBTITLE(state, newValue) {
        state.subTitle = newValue;
    },
    SET_HASHMOVE(state, newValue) {
        state.hashMove = newValue;
    },
    DELETE_BREADCUMB(state, newValue) {
        state.breadcumbs = newValue;
    },
    SET_BREADCUMB(state, newValue) {
        // let indexData = state.breadcumbs.findIndex((el)=>{
        //     return el._id == newValue._id
        // });
        // if(indexData < 0){
        //     state.breadcumbs.push(newValue);
        // }else{
        //     state.breadcumbs = state.breadcumbs.slice(0,indexData+1);
        // }
        state.breadcumbs = newValue
    },
    SET_OFFSET(state, newValue) {
        state.offset = newValue;
    },
    SET_KEYWORD(state, newValue) {
        state.keyword = newValue;
    },
    SET_PARENT_ID(state, newValue) {
        state.parent_id = newValue;
    },
    SET_ITEMS(state, newValue) {
        state.items = newValue;
    },
    SET_ITEM(state, newValue) {
        state.item = newValue;
    },
    SET_LOADING(state, newValue) {
        state.loading = newValue;
    },
    SET_RENAME(state,newValue){
        state.items[newValue].isEdit = !state.items[newValue].isEdit 
    },
    SET_RENAME_ITEM(state,newValue){
        state.newName = newValue
    },
    SET_SELECTED_ONE(state,value){
        let index = state.selected.findIndex((el)=>{
            return el._id == value._id
        });
        if(index < 0){
            state.selected.push(value);
        }else{
            state.selected = state.selected.filter((el)=>{
                return value._id
            });
            // state.selected.splice(index,1);
        }
    },
    SET_ONE_SELECTED(state,value){
        state.selected = [value];
    },
    SET_SELECTED(state,value){
        let index = state.selected.findIndex((el)=>{
            return el._id == value._id
        });
        if(index < 0){
            state.selected.push(value);
        }else{
            state.selected = state.selected.filter((el)=>{
                return el._id != value._id
            });
            // state.selected.splice(index,1);
        }
    },
    SET_ALL_SELECTED(state,value){
        state.selected = value
    },
    SET_SHOW_PROPERTIES(state,value){
        state.showProperties = value
    },
    SET_SHOW_GRID(state,value){
        state.gridView = value
    },
    SET_SIDERIGHT_TOGGLE(state,value){
        state.sideRightToggle = value
    },
    SET_NOTIF_TOGGLE(state,value){
        state.notifToggle = value
    },
    SET_DISPLAY_SIDEBAR(state,value){
        state.displaySidebar = value
    }
}

export const getters = {
    canAccessFile(state) {
        return state.canAccessFile
    },
    linkS3(state) {
        return state.linkS3
    },
    displaySidebar(state) {
        return state.displaySidebar
    },
    newName(state) {
        return state.newName
    },
    sideRightToggle(state) {
        return state.sideRightToggle
    },
    limit(state) {
        return state.limit
    },
    publicShare(state) {
        return state.publicShare
    },
    totalLoad(state) {
        return state.totalLoad
    },
    totalItem(state) {
        return state.totalItem
    },
    offset(state) {
        return state.offset
    },
    keyword(state) {
        return state.keyword
    },
    parent_id(state) {
        return state.parent_id
    },
    items(state) {
        return state.items
    },
    item(state) {
        return state.item
    },
    loading(state) {
        return state.loading
    },
    breadcumbs(state) {
        return state.breadcumbs
    },
    hashMove(state) {
        return state.hashMove
    },
    showBreadcumb(state) {
        return state.showBreadcumb
    },
    subTitle(state) {
        return state.subTitle
    },
    recentList(state) {
        return state.recentList
    },
    linkFile(state){
        return state.linkFile
    },
    selected(state){
        return state.selected
    },
    gridView(state){
        return state.gridView
    },
    showProperties(state){
        return state.showProperties;
    },
    notifToggle(state){
        return state.notifToggle
    }
}

export const actions = {
    increamentItems(context){
        let currentLimit = context.getters["limit"]
        let totalItems = context.getters["totalItem"]

        if (currentLimit < totalItems) {
            // Increase the limit by 10
            currentLimit += 50;
            // Ensure the limit does not exceed total items
            if (currentLimit > totalItems) {
                currentLimit = totalItems;
            }
        } else {
            // Set the limit to total items if currentLimit >= totalItems
            currentLimit = totalItems;
        }
        
        context.commit("SET_LIMIT", currentLimit)
    },
    setSidebarDisplay(context, value){
        const firstSidebar = document.getElementById('side-bar-menu')
        const secondSidebar = document.getElementById('side-bar-menu-2')
        const btnExpandSidebar = document.getElementById('expand-side-btn')
        const toggleButton = document.getElementById('second-sidebar-toggle')
        const dropSecondSide = document.querySelectorAll('.dropdown-more-scnd-side')

        const primaryWgList = document.getElementById('primary-wg')
        const imgTogglePrimary = document.getElementById('primary-wg-img-toggle')

        if(primaryWgList){
            if(primaryWgList.classList.contains('active')){
                imgTogglePrimary.style.transform = 'rotate(180deg)'
            } else {
                imgTogglePrimary.style.transform = 'rotate(0deg)'
            }
        }

        const enterpriseWgList = document.getElementById('enterprise-wg')
        const imgToggleEnterprise = document.getElementById('enterprise-wg-img-toggle')

        if(enterpriseWgList){
            if(enterpriseWgList.classList.contains('active')){
                imgToggleEnterprise.style.transform = 'rotate(180deg)'
            } else {
                imgToggleEnterprise.style.transform = 'rotate(0deg)'
            }
        }

        if(value == true){
            firstSidebar.style.opacity = 0
            firstSidebar.style.visibility = 'hidden'
            // firstSidebar.style.display = 'none'
            // secondSidebar.style.display = 'block'
            secondSidebar.style.opacity = 1
            secondSidebar.style.visibility = 'visible'
            secondSidebar.style.left = 0
            btnExpandSidebar.style.opacity = 1
            btnExpandSidebar.style.visibility = 'visible'
            // btnExpandSidebar.style.display = 'block'
            dropSecondSide.forEach((v) => {
                v.style.left = '190px'
            })
    
            if(!secondSidebar.classList.contains('active')){
                secondSidebar.classList.add('active')
            }
            context.commit('SET_DISPLAY_SIDEBAR', value)

        } else {
            firstSidebar.style.opacity = 1
            firstSidebar.style.visibility = 'visible'
            // firstSidebar.style.display = 'block'
            // secondSidebar.style.display = 'none'
            secondSidebar.style.opacity = 0
            secondSidebar.style.visibility = 'hidden'
            secondSidebar.style.left = '227px'
            btnExpandSidebar.style.opacity = 0
            btnExpandSidebar.style.visibility = 'hidden'
            // btnExpandSidebar.style.display = 'none'
            dropSecondSide.forEach((v) => {
                v.style.left = '415px'
            })
    
            // if(secondSidebar.classList.contains('active')){
            //     secondSidebar.classList.remove('active')
            // }
            context.commit('SET_DISPLAY_SIDEBAR', value)
        }
    },
    setSideRightToggle(context, value){
        // if(value.isShowing && value.isPersistent && !document.body.classList.contains("logo-box-sideRight-shrink")) {
        //     document.body.classList.add("logo-box-sideRight-shrink")
        // }
     
        // if(value.isPersistent && !document.body.classList.contains("logo-box-sideRight-shrink") && value.isShowing){
        //     document.body.classList.toggle("logo-box-sideRight-shrink")
        // }

        var mainpage = document.getElementById("content-pages");
        var detailItem = document.getElementById("show-details-info-sideRight")
        var sharedItem = document.getElementById("show-shared-sideRight")
        var participantList = document.getElementById("show-participant-sidebarRight")
        var filesChat = document.getElementById("show-files-in-chat")
        if(value.isShowing){
            mainpage.style.marginRight = "50px";
            filesChat.style.display = "none"
            detailItem.style.display = "none";
            sharedItem.style.display = "none";
            participantList.style.display = "none";
            document.body.classList.remove("logo-box-sideRight-shrink")
            context.commit("SET_SIDERIGHT_TOGGLE", value.isShowing)
        } else {
            mainpage.style.marginRight = "350px";
            detailItem.style.display = "block"
            document.body.classList.add("logo-box-sideRight-shrink")
            context.commit("SET_SIDERIGHT_TOGGLE", value.isShowing)
        }
        // if(!value.isShowing){
        //     mainpage.style.marginRight = "50px";
        //     filesChat.style.display = "none"
        //     detailItem.style.display = "none";
        //     sharedItem.style.display = "none";
        //     participantList.style.display = "none";
        //     document.body.classList.remove("logo-box-sideRight-shrink")
        //     context.commit("SET_SIDERIGHT_TOGGLE", value.isShowing)
        // } else {
        //     mainpage.style.marginRight = "350px";
        //     document.body.classList.add("logo-box-sideRight-shrink")
        //     context.commit("SET_SIDERIGHT_TOGGLE", value.isShowing)
        // }
    },
    sorting(context,method){
        const { commit, getters } = context;
        commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        const list = [...getters["items"]];
        var data = storage.sorting(
            list,
            method
        );
        commit("SET_ITEMS",data);
        commit("SET_LOADING",false);
    },
    listing(context,deleted="") {
        const { commit, getters } = context;
        commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        if(deleted != "noclear"){
            context.commit("SET_ALL_SELECTED",[]);
            // context.commit("SET_ALL_SELECTED",[],);
        }else{
            deleted = ""
        }
        // context.commit("SET_ALL_SELECTED",[]);
        return new Promise((resolve, reject) => {
            storage.listing(
                getters["keyword"],
                getters["parent_id"],
                deleted
            )
            .then((result)=>{
                commit('SET_ITEMS', result.data);
                commit('SET_TOTALLOAD', result.totalLoad);
                commit('SET_TOTALITEM', result.totalData);
                commit("SET_LOADING",false);
                commit("SET_BREADCUMB", result.breadcumbs)
                context.dispatch("user/getLimit",null,{root:true});
                resolve(result);
            })
            .catch((err)=>{
                commit('SET_TOTALLOAD', 0);
                commit('SET_TOTALITEM', 0);
                commit('SET_ITEMS', []);
                commit("SET_LOADING",false);
                context.commit("error/SET_MESSAGE",err,{root:true});
                context.commit("error/SET_ERROR",true,{root:true});
                reject(err);
            });
        });
    },
    back(context){
        const { commit, getters,dispatch } = context;
        var breadCumbs = getters["breadcumbs"];
        if(breadCumbs.length > 1){
            commit("SET_PARENT_ID",breadCumbs[breadCumbs.length - 2]._id);
            // commit("SET_BREADCUMB",breadCumbs[breadCumbs.length-2]);
            commit("SET_KEYWORD","");
            dispatch("listing");
        }else{
            commit("DELETE_BREADCUMB",[]);
            commit("SET_PARENT_ID","");
            commit("SET_KEYWORD","");
            dispatch("listing");
        }
    },
    newFolder(context,data) {
        const { commit } = context;
        commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        return new Promise((resolve, reject) => {
            storage.newFolder(
                data
            )
            .then((result)=>{
                commit("SET_LOADING",false);
                // dispatch("listing");
                context.dispatch("user/getLimit",null,{root:true});
                resolve(result);
            })
            .catch((err)=>{
                commit("SET_LOADING",false);
                context.commit("error/SET_MESSAGE",err,{root:true});
                context.commit("error/SET_ERROR",true,{root:true});
                reject(err);
            });
        });
    },
    createFile(context,data) {
        const { commit,dispatch } = context;
        commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        return new Promise((resolve, reject) => {
            storage.createFile(
                data
            )
            .then((result)=>{
                commit("SET_LOADING",false);
                dispatch("listing");
                context.dispatch("user/getLimit",null,{root:true});
                resolve(result);
            })
            .catch((err)=>{
                commit("SET_LOADING",false);
                context.commit("error/SET_MESSAGE",err.data.display_message,{root:true});
                context.commit("error/SET_ERROR",true,{root:true});
                reject(err);
            });
        });
    },
    rename(context, value) {
        const { commit,dispatch } = context;
        // var nameItem = context.getters["newName"]
        var data = {
            id: value._id,
            file_name: value.file_name
        }
        commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        return new Promise((resolve, reject) => {
            storage.rename(
                data
            )
            .then((result)=>{
                commit("SET_LOADING",false);
                dispatch("listing");
                context.dispatch("user/getLimit",null,{root:true});
                resolve(result);
            })
            .catch((err)=>{
                commit("SET_LOADING",false);
                context.commit("error/SET_MESSAGE",err,{root:true});
                context.commit("error/SET_ERROR",true,{root:true});
                reject(err);
            });
        });
    },
    move(context,id) {
        const { commit,dispatch,getters } = context;
        var breadCumbs = getters["breadcumbs"];
        var formData = new FormData();
        formData.append("src_hash",id);
        formData.append("dest_hash", breadCumbs.length > 0 ? breadCumbs[breadCumbs.length-1].hash_name : "");
        commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        return new Promise((resolve, reject) => {
            storage.move(
                formData
            )
            .then((result)=>{
                commit("SET_LOADING",false);
                dispatch("listing");
                context.dispatch("user/getLimit",null,{root:true});
                resolve(result);
            })
            .catch((err)=>{
                commit("SET_LOADING",false);
                context.commit("error/SET_MESSAGE",err,{root:true});
                context.commit("error/SET_ERROR",true,{root:true});
                reject(err);
            });
        });
    },
    moveFolder(context,id) {
        const { commit,dispatch,getters } = context;
        var breadCumbs = getters["breadcumbs"];
        var param = {
            target_id : id,
            destination_id : breadCumbs.length > 0 ? breadCumbs[breadCumbs.length-1]._id : ""
        }
        commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        return new Promise((resolve, reject) => {
            storage.moveFolder(
                param
            )
            .then((result)=>{
                commit("SET_LOADING",false);
                dispatch("listing");
                context.dispatch("user/getLimit",null,{root:true});
                resolve(result);
            })
            .catch((err)=>{
                commit("SET_LOADING",false);
                context.commit("error/SET_MESSAGE",err,{root:true});
                context.commit("error/SET_ERROR",true,{root:true});
                reject(err);
            });
        });
    },
    copyFolder(context,id) {
        const { commit,dispatch,getters } = context;
        var breadCumbs = getters["breadcumbs"];
        var param = {
            target_id : id,
            destination_id : breadCumbs.length > 0 ? breadCumbs[breadCumbs.length-1]._id : ""
        }
        commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        return new Promise((resolve, reject) => {
            storage.copyFolder(
                param
            )
            .then((result)=>{
                commit("SET_LOADING",false);
                dispatch("listing");
                context.dispatch("user/getLimit",null,{root:true});
                resolve(result);
            })
            .catch((err)=>{
                commit("SET_LOADING",false);
                context.commit("error/SET_MESSAGE",err,{root:true});
                context.commit("error/SET_ERROR",true,{root:true});
                reject(err);
            });
        });
    },
    copy(context,id) {
        const { commit,dispatch,getters } = context;
        var breadCumbs = getters["breadcumbs"];
        var formData = new FormData();
        formData.append("src_hash_name",id);
        formData.append("dest_hash_name", breadCumbs.length > 0 ? breadCumbs[breadCumbs.length-1].hash_name : "");
        commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        return new Promise((resolve, reject) => {
            storage.copy(
                formData
            )
            .then((result)=>{
                commit("SET_LOADING",false);
                dispatch("listing");
                context.dispatch("user/getLimit",null,{root:true});
                resolve(result);
            })
            .catch((err)=>{
                commit("SET_LOADING",false);
                context.commit("error/SET_MESSAGE",err,{root:true});
                context.commit("error/SET_ERROR",true,{root:true});
                reject(err);
            });
        });
    },
    fileretrieve(context,data) {
        const { commit} = context;
        // commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        return new Promise((resolve, reject) => {
            storage.fileRetrieve(
                data._id,
                data.directory
            )
            .then((result)=>{
                storage.globalFileRetrieve(
                    data._id,
                    data.directory
                )
                .then(()=>{
                    commit("SET_PUBLIC_SHARE",true);
                })
                .catch(()=>{
                    commit("SET_PUBLIC_SHARE",false);
                })
                commit("SET_LOADING",false);
                commit("SET_ITEM",result);
                commit("SET_LINK_FILE_S3", result.url)
                context.dispatch("user/getLimit",null,{root:true});
                resolve(result);
            })
            .catch((err)=>{
                commit("SET_LOADING",false);
                context.commit("error/SET_MESSAGE",err,{root:true});
                context.commit("error/SET_ERROR",true,{root:true});
                reject(err);
            });
        });
    },
    download(context,data) {
        const { commit} = context;
        commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        return new Promise((resolve, reject) => {
            storage.downloadfile(
                data
            )
            .then((result)=>{
                commit("SET_LOADING",false);
                context.dispatch("user/getLimit",null,{root:true});
                resolve(result);
            })
            .catch((err)=>{
                commit("SET_LOADING",false);
                context.commit("error/SET_MESSAGE",err,{root:true});
                context.commit("error/SET_ERROR",true,{root:true});
                reject(err);
            });
        });
    },
    downloadFolder(context,data) {
        const { commit} = context;
        commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        return new Promise((resolve, reject) => {
            storage.downloadFolder(
                data
            )
            .then((result)=>{
                commit("SET_LOADING",false);
                context.dispatch("user/getLimit",null,{root:true});
                resolve(result);
            })
            .catch((err)=>{
                commit("SET_LOADING",false);
                context.commit("error/SET_MESSAGE",err,{root:true});
                context.commit("error/SET_ERROR",true,{root:true});
                reject(err);
            });
        });
    },
    isStorageFull(context) {
        context.dispatch("user/getLimit",null,{root:true})
        .then((res) => {
            var alertContainer = document.getElementById('alert-storage-v3')
            var sizeDirectory = context.rootGetters['user/size_directory']
            var usedDirectory = context.rootGetters['user/total_used']
            var emptyDirectory = sizeDirectory - usedDirectory
            
            if(emptyDirectory <= 104857600) { // 104857600 or 100MB
                alertContainer.style.display = 'block'
            } else {
                alertContainer.style.display = 'none'
            }
            
            // FOR TESTING
            // var sizeDirectory = 10
            // var usedDirectory = 8
            // var emptyDirectory = sizeDirectory - usedDirectory
            
            // if(emptyDirectory <= 2) {
            //     alertContainer.style.display = 'block'
            // } else {
            //     alertContainer.style.display = 'none'
            // }
        })
    },
    statSubs(context) {
        context.dispatch("user/getLimit",null,{root:true})
        .then((res) => {
            var alertContainer = document.getElementById('alert-status-subs-v3')
            var mainContent = document.getElementById('content-pages')
            
            var sizeDirectory = context.rootGetters['user/size_directory']
            var usedDirectory = context.rootGetters['user/total_used']
            var emptyDirectory = sizeDirectory - usedDirectory
            
            if(res[0].cancel_sub == true) {
                alertContainer.style.display = 'block'
                if(res[0].cancel_sub == true || emptyDirectory <= 104857600){
                    mainContent.style.marginTop = '48px'
                }
            } else {
                alertContainer.style.display = 'none'
                if(res[0].cancel_sub == false && emptyDirectory > 104857600){
                    mainContent.style.marginTop = '0px'
                }
            }
        })
    },
    beforeUploaded(context, data) {
        const { commit,dispatch } = context;
        commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        return new Promise((resolve, reject) => {
            storage.beforeUpload(
                data
            )
            .then((result)=>{
                commit("SET_LOADING",false);
                dispatch("listing");
                context.dispatch("user/getLimit",null,{root:true});
                resolve(result);
            })
            .catch((err)=>{
                commit("SET_LOADING",false);
                // context.commit("error/SET_MESSAGE",err,{root:true});
                // context.commit("error/SET_ERROR",true,{root:true});
                reject(err);
            });
        });
    },
    upload(context,data) {
        const { commit,dispatch } = context;
        commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        return new Promise((resolve, reject) => {
            storage.upload(
                data
            )
            .then((result)=>{
                commit("SET_LOADING",false);
                dispatch("listing");
                context.dispatch("user/getLimit",null,{root:true});
                resolve(result);
            })
            .catch((err)=>{
                commit("SET_LOADING",false);
                // context.commit("error/SET_MESSAGE",err,{root:true});
                // context.commit("error/SET_ERROR",true,{root:true});
                reject(err);
            });
        });
    },

    uploadFolder(context,data) {
        const { commit,dispatch } = context;
        commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        return new Promise((resolve, reject) => {
            storage.uploadFolder(
                data
            )
            .then((result)=>{
                commit("SET_LOADING",false);
                dispatch("listing");
                context.dispatch("user/getLimit",null,{root:true});
                resolve(result);
            })
            .catch((err)=>{
                commit("SET_LOADING",false);
                // context.commit("error/SET_MESSAGE",err,{root:true});
                // context.commit("error/SET_ERROR",true,{root:true});
                reject(err);
            });
        });
    },
    // multiDelete(context,arrid){

    //     return new Promise.all()
    // },
    deleteFile(context,id) {
        const { commit,dispatch } = context;
        commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        return new Promise((resolve, reject) => {
            storage.deleteFile(
                id
            )
            .then((result)=>{
                commit("SET_LOADING",false);
                dispatch("listing");
                context.dispatch("user/getLimit",null,{root:true});
                resolve(result);
            })
            .catch((err)=>{
                commit("SET_LOADING",false);
                context.commit("error/SET_MESSAGE",err.data.display_message,{root:true});
                context.commit("error/SET_ERROR",true,{root:true});
                reject(err);
            });
        });
    },
    deleteForever(context,id) {
        const { commit,dispatch } = context;
        commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        return new Promise((resolve, reject) => {
            storage.deleteForever(
                id
            )
            .then((result)=>{
                commit("SET_LOADING",false);
                dispatch("listing","1");
                context.dispatch("user/getLimit",null,{root:true});
                resolve(result);
            })
            .catch((err)=>{
                commit("SET_LOADING",false);
                context.commit("error/SET_MESSAGE",err,{root:true});
                context.commit("error/SET_ERROR",true,{root:true});
                reject(err);
            });
        });
    },
    restore(context,id) {
        const { commit,dispatch } = context;
        commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        return new Promise((resolve, reject) => {
            storage.restore(
                id
            )
            .then((result)=>{
                commit("SET_LOADING",false);
                dispatch("listing","1");
                context.dispatch("user/getLimit",null,{root:true});
                resolve(result);
            })
            .catch((err)=>{
                commit("SET_LOADING",false);
                context.commit("error/SET_MESSAGE",err,{root:true});
                context.commit("error/SET_ERROR",true,{root:true});
                reject(err);
            });
        });
    },
    recent(context) {
        // const { commit } = context;
        // commit("SET_LOADING",true);
        // context.commit("error/SET_MESSAGE","",{root:true});
        // context.commit("error/SET_ERROR",false,{root:true});
        // context.commit("error/SET_SUCCESS",false,{root:true});
        // return new Promise((resolve, reject) => {
        //     storage.getRecent()
        //     .then((result)=>{
        //         commit("SET_LOADING",false);
        //         commit("SET_RECENTLIST",result);
        //         context.dispatch("user/getLimit",null,{root:true});
        //         resolve(result);
        //     })
        //     .catch((err)=>{
        //         commit("SET_LOADING",false);
        //         context.commit("error/SET_MESSAGE",err,{root:true});
        //         context.commit("error/SET_ERROR",true,{root:true});
        //         reject(err);
        //     });
        // });
    },
    open(context,item) {
        const { commit } = context;
        commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        return new Promise((resolve, reject) => {
            storage.openFile(item)
            .then((result)=>{
                commit("SET_LOADING",false);
                context.dispatch("user/getLimit",null,{root:true});
                resolve(result);
            })
            .catch((err)=>{
                commit("SET_LOADING",false);
                context.commit("error/SET_MESSAGE",err,{root:true});
                context.commit("error/SET_ERROR",true,{root:true});
                reject(err);
            });
        });
    },
    getLinkFile(context,item) {
        const { commit } = context;
        commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        return new Promise((resolve, reject) => {
            storage.getLinkFile(item)
            .then((result)=>{
                commit("SET_LINKFILE",result);
                commit("SET_LOADING",false);
                context.dispatch("user/getLimit",null,{root:true});
                resolve(result);
            })
            .catch((err)=>{
                commit("SET_LOADING",false);
                context.commit("error/SET_MESSAGE",err,{root:true});
                context.commit("error/SET_ERROR",true,{root:true});
                reject(err);
            });
        });
    },
    annotationApryse(context,data) {
        const { commit } = context;
        commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        return new Promise((resolve, reject) => {
            storage.saveAnnotation(data)
            .then((result)=>{
                commit("SET_LINKFILE",result);
                commit("SET_LOADING",false);
                context.dispatch("user/getLimit",null,{root:true});
                resolve(result);
            })
            .catch((err)=>{
                commit("SET_LOADING",false);
                context.commit("error/SET_MESSAGE",err,{root:true});
                context.commit("error/SET_ERROR",true,{root:true});
                reject(err);
            });
        });
    },
    GlobalFiles(context,item) {
        const { commit } = context;
        commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        return new Promise((resolve, reject) => {
            storage.globalFileRetrieve(item.id,item.directory)
            .then((result)=>{
                commit("SET_ITEM",result);
                commit("SET_LOADING",false);
                // context.dispatch("user/getLimit",null,{root:true});
                resolve(result);
            })
            .catch((err)=>{
                commit("SET_LOADING",false);
                context.commit("error/SET_MESSAGE",err,{root:true});
                context.commit("error/SET_ERROR",true,{root:true});
                reject(err);
            });
        });
    },

    multipleDownload(context,data) {
        const { commit } = context;
        commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        return new Promise((resolve, reject) => {
            storage.downloadMultiple(data)
            .then((result)=>{
                commit("SET_ITEM",result);
                commit("SET_LOADING",false);
                // context.dispatch("user/getLimit",null,{root:true});
                resolve(result);
            })
            .catch((err)=>{
                commit("SET_LOADING",false);
                context.commit("error/SET_MESSAGE",err,{root:true});
                context.commit("error/SET_ERROR",true,{root:true});
                reject(err);
            });
        });
    },
    uploadTus(context,data){
        const {file,metadata,filesData,isFolder,dest} = data;
        return new Promise((resolve,reject)=>{
            // Create a new tus upload
            var upload = new tus.Upload(file, {
                endpoint: process.env.VUE_APP_TUS,
                retryDelays: [0, 3000, 5000, 10000, 20000],
                chunkSize : 1000000,
                metadata: metadata,
                // eslint-disable-next-line no-unused-vars
                onError: function(error) {
                    reject(error)
                    context.commit("upload/STATUS",{id:filesData.id,status:4,error:"Upload Failed"},{root:true});
                },
                onProgress: function(bytesUploaded, bytesTotal) {
                    var percentage = (bytesUploaded / bytesTotal * 100).toFixed(2)
                    context.commit("upload/ADD_PERCENTAGE",{id:filesData.id,percentage:parseInt(percentage)},{root:true});
                    context.commit("upload/STATUS",{id:filesData.id,status:2,error:""},{root:true});
                },
                onSuccess: function() {
                    context.commit("upload/STATUS",{id:filesData.id,status:3,error:""},{root:true});
                    if(isFolder){
                        resolve({
                            hash_name : upload.url.split('/').pop(),
                            file_name : file.name,
                            extension : file.name.split('.').pop() == file.name ? 'txt' : file.name.split('.').pop(),
                            size : file.size,
                            path : file.webkitRelativePath
                        });
                        context.commit("upload/UPDATE_UPLOADED",{id:filesData.id},{root:true});
                    }else{
                        var formData = new FormData();
                        formData.append("parent_id",dest);
                        formData.append("hash_name",upload.url.split('/').pop());
                        formData.append("file_name",file.name);
                        formData.append("extension",file.name.split('.').pop() == file.name ? 'txt' : file.name.split('.').pop());
                        formData.append("size",file.size);
                        context.dispatch("upload",formData)
                        .then((v)=>{
                            if(!isFolder){
                                context.commit("upload/STATUS",{id:filesData.id,status:3,error:v},{root:true});
                                context.commit("upload/DONE",{id:filesData.id,done:true},{root:true});
                            }
                        })
                        .catch((e)=>{
                            context.commit("upload/DONE",{id:filesData.id,done:true},{root:true});
                            context.commit("upload/STATUS",{id:filesData.id,status:4,error:e},{root:true});
                        });
                        resolve({
                            hash_name : upload.url.split('/').pop(),
                            file_name : file.name,
                            extension : file.name.split('.').pop() == file.name ? 'txt' : file.name.split('.').pop(),
                            size : file.size
                        });
                    }
                }
                
            });
            // Check if there are any previous uploads to continue.
            upload.findPreviousUploads().then(function (previousUploads) {
                // Found previous uploads so we select the first one. 
                if (previousUploads.length) {
                    upload.resumeFromPreviousUpload(previousUploads[0])
                }
                // Start the upload
                upload.start()
            })  
        })
    }
}