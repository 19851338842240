const getNameSubDomain = () => {
    const url = window.location.host;
    const defaultDomain = ['jaybod','jayent','jaybodenterprise'];
    let subdomain = '';
    let splitURL = url.split('.');
    if(url.includes(['localhost'])){
        subdomain = 'jaybod-fe';
    } else {
        subdomain = defaultDomain.includes(splitURL[1]) ? splitURL[0] : url;
    }
    return subdomain;
}

export default getNameSubDomain;