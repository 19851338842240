import io from 'socket.io-client'
import store from '../state/store';

export const SocketAPI = {
    socket : null,
    listWorkg : [],
    chats : [],
    connect(user_id,token){
        // this.socket = io("wss://chat.jaybod.com",{ query: `user_id=${user_id}`,auth:{token:token},transports: ['websocket'], upgrade: false });
        this.socket = io(process.env.VUE_APP_SOCKET_IO,{ query: `user_id=${user_id}`,auth:{token:token},transports: ['websocket'], upgrade: false }, {reconnection: false});
        
        this.socket.on('connect', (sockets) => {
            // alert("connect")
        });
        this.socket.on("connect_error", (err) => {
        })
        this.socket.on('USER_ONLINE',param=>{
            // param.users.forEach(el => {
            //     store.dispatch('workgroup/retrieve',el.user_id)
            // })
            // this.listWorkg.push(param)
            // store.commit('chat/SET_LIST_GROUP', param)
            store.dispatch('chat/setList',param)
            // store.commit('error/addNotif',param)
        })

        this.socket.on('HAVE_MESSAGE',param=>{
            // this.chats.push(param);
            // store.commit('chat/SET_CHAT',param)
            if(param.file != null){
                // store.commit('chat/REMOVE_MEDIAS_CHAT', [])
                store.dispatch('chat/setNewMedia',param)
            }
            store.dispatch('chat/sortingChat',param)

            // store.commit('error/addNotif',param)
        })

        this.socket.on('CHATS_HISTORY',param => {
            param.chats.forEach(el => {
                store.dispatch('chat/sortingChat', el)
            })
        })
        
        this.socket.on('CHAT_BOARD',param=>{
            store.commit('chat/SET_GROUP',param.group)
            store.dispatch('chat/sortingParticipant',param.group.users)
            store.commit('chat/REMOVE_MEDIAS_CHAT', [])
            store.dispatch('chat/mediasInChat', param.media)
            // store.commit('chat/SET_CHAT',param.chats)
            param.chats.forEach(el => {
                store.dispatch('chat/sortingChat', el)
            })
        })

        this.socket.on('USER_OFFLINE',param=>{
            store.dispatch('chat/sortParticipantOffline',param)
        })

        this.socket.on('NOTIFS',param=>{
            // store.dispatch('notif/filteringNotifs', param)
        })
        
        this.socket.on('NOTIF',param=>{
            store.dispatch('notif/newNotif', param)
        })

        this.socket.on("DELETED_CHAT", param => {
            store.dispatch('chat/setDeletedChat', param)
        })

        this.socket.on("UPDATE_FILE_CHAT", param => {
            store.dispatch('chat/updateChatFile', param)
        })

        this.socket.on("DELETED_CHATS", param => {
            store.dispatch('chat/setDeletedChats', param)
        })
    },
    isConnected(){
        return this.socket.connected
    },
    send(user_id,workgroup,msg){
        this.socket.emit("SEND_CHAT",{user:user_id,group:workgroup,message:msg})
        
    },
    open(user_id,workgroup){
        this.socket.emit("OPEN_CHAT",{user:user_id,group:workgroup})
    },
    load(start,limit,group){
        this.socket.emit('HISTORY',{start,limit,group})
    },
    newGroup(payload){
        this.socket.emit("NEW_GROUP",payload)
    },
    readNotif(payload){
        this.socket.emit("READ_NOTIF",payload)
    },
    deletedChat(payload){
        this.socket.emit("DELETE_CHAT",{group: payload.group, id: payload.id_chat})
    },
    logout(){
        this.socket.disconnect();
        this.socket.close();
    }
}
