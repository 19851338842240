export const state = {
    files : []
}

export const mutations = {
    ADD_FILES(state, newValue) {
        state.files.push(newValue)
    },
    ADD_PERCENTAGE(state,data){
        var index = state.files.findIndex((v)=>v.id==data.id);
        if(index >= 0){
            state.files[index].percentage = data.percentage
        }
    },
    DONE(state,data){
        var index = state.files.findIndex((v)=>v.id==data.id);
        if(index >= 0){
            state.files[index].done = data.done
        }
    },
    STATUS(state,data){
        var index = state.files.findIndex((v)=>v.id==data.id);
        if(index >= 0){
            state.files[index].status = data.status
            state.files[index].error = data.error
        }
    },
    UPDATE_UPLOADED(state,data){
        var index = state.files.findIndex((v)=>v.id==data.id);
        if(index >= 0){
            state.files[index].uploaded += 1;
        }
    },
    REMOVE(state,index){
        var indexFile = state.files.findIndex(v => {
            return v.id == index
        })
        state.files.splice(indexFile,1);
    },
    CLEAR(state){
        state.files = []
    }
}

export const getters = {
    files(state) {
        return state.files
    },
}


export const actions = {
}