import Client from "./client";

export default class Firebase extends Client {
    constructor() {
        super();
    }
    storeFirebaseToken(user_id,firebase_token){
        return new Promise(function(resolve,reject){
            var payload = {
                user_id: user_id,
                firebase_token: firebase_token
            }
            this.post(`profile/firebaseToken`, payload)
            .then((result)=>{
                if(result.status == 200){
                    resolve(result);
                }else{
                    reject(result.display_message)
                }
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
}