import Client from "./client";
import moment from 'moment';
import store from '../state/store';

export default class Storage extends Client{
    constructor() {
        super();
    }
    listImg =  ["jpg", "jpeg", "png", "gif", "svg"];
    listAudio = ["mp3"];
    listWord = ["dox", "doc", "docx"];
    listExcel = ["xls", "xlsx", "csv"];
    listPPt = ["ppt", "pptx"];
    listCompres = ["rar", "zip", "tar.gz"];
    listVideo = ["mp4", "mkv"];
    listPdf = ["pdf"];
    listtxt = ["txt"];
    listing(keyword,id,deleted){
        return new Promise(function(resolve,reject){
            this.get(`files/?keyword=${keyword}&parent_id=${id}&deleted=${deleted}`)
            .then((result)=>{
                if(result.status == 200){
                    var resultData = this.filter(result.data);
                    resolve({data:resultData,totalLoad:resultData.length,totalData:result.recordsTotal, breadcumbs:result.breadcumbs});
                }else{
                    reject(result.display_message)
                }
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    getImage(ext) {
        let img = "Etc.svg"
        if (this.listImg.indexOf(ext) >= 0) {
            img = "Image.svg"
        } else if (this.listAudio.indexOf(ext) >= 0) {
            img = "Music.svg"
        } else if (this.listWord.indexOf(ext) >= 0) {
            img = "Docs.svg"
        } else if (this.listExcel.indexOf(ext) >= 0) {
            img = "XLS.svg"
        } else if (this.listPPt.indexOf(ext) >= 0) {
            img = "PPT.svg"
        } else if (this.listCompres.indexOf(ext) >= 0) {
            img = "Zip.svg"
        } else if (this.listVideo.indexOf(ext) >= 0) {
            img = "Video.svg"
        } else if (this.listPdf.indexOf(ext) >= 0) {
            img = "PDF.svg"
        } else if (this.listtxt.indexOf(ext) >= 0) {
            img = "TXT.svg"
        } else {
            img = "Etc.svg"
        }
        return img;
    }
    getSize(bytes){
        var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes == 0) return '0 Byte';
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
    }
    splitDir(list) {
        let files = [];
        list.forEach((item) => {
            if (item.type != 2) {
                files.push(item)
            }
        })
        var folders = list.filter(function(e) { return e.type == 2 });

        return {folders,files}
    }
    sorting(list, method) {
        list.sort((a, b) => {
                if (a.file_name.toLowerCase() < b.file_name.toLowerCase()) { return method == 'ASC' ? -1 : 1 }
                if (a.file_name.toLowerCase() > b.file_name.toLowerCase()) { return method == 'ASC' ? 1 : -1 }
                return 0;
        })
        return list;
    }

    filter(data){
        let {folders,files} = this.splitDir(data);
        folders = this.sorting(folders,"ASC");
        files = this.sorting(files,"ASC");
        data = [...folders,...files];
        data = data.map((value)=>{
            return {
                // datecreated : moment(value.datecreated).fromNow(),
                // datemodified : moment(value.datemodified).fromNow(),
                encrypt : value.encrypt ? value.encrypt.code : value.encrypt,
                datecreated : moment(value.datecreated).format('MMM DD, YYYY'),
                datemodified : moment(value.datemodified).format('MMM DD, YYYY'),
                extension : value.extension,
                file_name : value.file_name,
                isFolder : value.type == 2,
                icon : value.type == 2 ? "Folder.svg" : this.getImage(value.extension.toLowerCase()),
                hash_name : value.hash_name,
                parent_id : value.parent_id,
                parent_folder : value.parent ? value.parent : null,
                size : value.type == 2 ? " - " : this.getSize(value.size),
                _id : value._id,
                idShare : false,
                isEdit : false,
                users_share : value.users_share,
                isShare : value.users_share ? value.users_share.length > 0 : true,
                isLinkShare : value.link_share ? value.link_share.length > 0 : true,
                directory : value.directory,
                checked : false,
                added_by : value.added_by,
                deleted_dated : value.deleted_dated ? value.deleted_dated : null,
                locked : !value.locked || value.locked == false ? false : true,
                annotation : value.annotation ? value.annotation : null,
                url : value.url ? value.url : null
            }
        })
        return data;
    }
    newFolder(data){
        return new Promise(function(resolve,reject){
            this.post(`files/mkdir`,data)
            .then((result)=>{
                if(result.status == 200){
                    resolve(result.display_message);
                }else{
                    reject(result.display_message)
                }
            })
            .catch((err) => {
                reject(err.response.data.display_message)
            });
        }.bind(this))
    }
    rename(data){
        return new Promise(function(resolve,reject){
            this.put(`files/rename`,data)
            .then((result)=>{
                if(result.status == 200){
                    resolve(result.display_message);
                }else{
                    reject(result.display_message)
                }
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    move(data){
        return new Promise(function(resolve,reject){
            this.postFormdata(`files/move`,data)
            .then((result)=>{
                if(result.status == 200){
                    resolve(result.display_message);
                }else{
                    reject(result.display_message)
                }
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    copy(data){
        return new Promise(function(resolve,reject){
            this.postFormdata(`files/copy`,data)
            .then((result)=>{
                if(result.status == 200){
                    resolve(result.message);
                }else{
                    reject(result.message);
                }
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    fileRetrieve(id,directory){
        return new Promise(function(resolve,reject){
            this.get(`files/retrieve/?id=${id}&directory=${directory}`)
            .then((result)=>{
                if(result.status == 200){
                    var resultData = this.filter([result.data]);
                    resolve(resultData[0]);
                }else{
                    reject(result.display_message)
                }
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    downloadfile(data){
        const {directory,hash_name,file_name} = data;
        return new Promise(function(resolve,reject){
            this.getBuffer(`files/download/?hash_name=${hash_name}&directory=${directory}`)
            .then((result)=>{
                const url = window.URL.createObjectURL(new Blob([result.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", file_name);
                document.body.appendChild(link);
                link.click();
                resolve(true)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    downloadFolder(data){
        const {id,file_name} = data;
        return new Promise(function(resolve,reject){
            this.getBuffer(`folder/download/?id=${id}`)
            .then((result)=>{
                const url = window.URL.createObjectURL(new Blob([result.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", file_name);
                document.body.appendChild(link);
                link.click();
                resolve(true)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    beforeUpload(data){
        return new Promise(function(resolve,reject){
            this.post('files/before_upload',data)
            .then((result)=>{
                if(result.status == 200){
                    resolve(result.message);
                }else if(result.status == 204){
                    reject(result.data)
                }else{
                    reject(result.message)
                }
            })
            .catch(err=>{
                reject(err.response)
            })
        }.bind(this))
    }
    upload(data){
        return new Promise(function(resolve,reject){
            this.postFormdata('files/upload',data)
            .then((result)=>{
                if(result.status == 200){
                    resolve(result.message);
                }else if(result.status == 204){
                    reject(result.data)
                }else{
                    reject(result.message)
                }
            })
            .catch(err=>{
                reject(err.response)
            });
        }.bind(this))
    }
    deleteFile(id){
        return new Promise(function(resolve,reject){
            this.hapus(`files/${id}`)
            .then((result)=>{
                if(result.status == 200){
                    resolve(result.message);
                }else{
                    reject(result.message)
                }
            })
            .catch(err=>{
                if(err.status == 500){
                    err.data.display_message = 'Ops something went wrong, try again later'
                }
                reject(err)
            });
        }.bind(this))
    }
    deleteForever(id){
        return new Promise(function(resolve,reject){
            this.hapus(`files/delete/${id}`)
            .then((result)=>{
                if(result.status == 200){
                    resolve(result.message);
                }else{
                    reject(result.message)
                }
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    getRecent(){
        return new Promise(function(resolve,reject){
            this.get(`files/recent/`)
            .then((result)=>{
                if(result.status == 200){
                    var resultData = this.filter(result.data);
                    resolve(resultData);
                }else{
                    reject(result.display_message)
                }
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    restore(id){
        return new Promise(function(resolve,reject){
            this.put(`files/restore/${id}`,{})
            .then((result)=>{
                if(result.status == 200){
                    resolve(result.display_message);
                }else{
                    reject(result.display_message)
                }
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    documentType(ext){
        const word = ["doc", "docm", "docx", "dot", "dotm", "dotx", "epub", "fodt", "fb2", "htm", "html", "mht", "odt", "ott", "pdf", "rtf", "txt", "djvu", "xml", "xps"];
        const cell = ["csv", "fods", "ods", "ots", "xls", "xlsm", "xlsx", "xlt", "xltm", "xltx"];
        const slide = ["fodp", "odp", "otp", "pot", "potm", "potx", "pps", "ppsm", "ppsx", "ppt", "pptm", "pptx"];
        if (word.indexOf(ext) >= 0) {
            return "word";
        } else if (cell.indexOf(ext) >= 0) {
            return "cell";
        } else if (slide.indexOf(ext) >= 0) {
            return "slide";
        } else {
            return "nothing";
        }
    }
    openFile(item){
        return new Promise(function(resolve,reject){
            this.get(`files/retrieve/?id=${item._id}&directory=${item.directory}`)
            .then((result)=>{
                if(result.status == 200){
                    const data = result.data;
                    const docType = this.documentType(data.extension);
                    if(docType != "nothing"){
                        const user = JSON.parse(localStorage.getItem('user'))[0];
                        // localStorage.setItem("linkfile", data.url)
                        store.commit('storage/SET_LINK_FILE_S3', data.url)
                        // window.open(`https://jaybod-fe.jaybod.xyz:3000/?docurl=${btoa(data.url)}&docID=${data._id}`,"_blank")

                        // const fullhost = location.protocol + '//' + location.host;
                        // const apiCallback = process.env.VUE_APP_CALBACK_API
                        // window.open(`${fullhost}/open.html?dir=${data.directory}&hash=${data.version}&title=${data.file_name}&filetype=${data.extension}&link=${data.url}&id=${user._id}&name=${user.firstname}&type=${docType}&apicallback=${apiCallback}`,"_blank");
                    }
                    resolve(true);
                }else{
                    reject(result.display_message)
                }
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    getLinkFile(item){
        return new Promise(function(resolve,reject){
            this.get(`files/retrieve/?id=${item._id}&directory=${item.directory}`)
            .then((result)=>{
                if(result.status == 200){
                    const data = result.data;
                    resolve(data.url);
                }else{
                    reject(result.display_message)
                }
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    saveAnnotation(item){
        return new Promise(function(resolve,reject){
            this.post(`files/saveAnnotation?id=${item._id}&directory=${item.directory}`, {xfdf: item.xfdf})
            .then((result)=>{
                if(result.status == 200){
                    resolve(result);
                }else{
                    reject(result.display_message)
                }
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    createFile(data){
        return new Promise(function(resolve,reject){
            this.post(`files/create`,data)
            .then((result)=>{
                if(result.status == 200){
                    const data = result.data[0];
                    const docType = this.documentType(data.extension);
                    if(docType != "nothing"){
                        const user = JSON.parse(localStorage.getItem('user'))[0];
                        const fullhost = location.protocol + '//' + location.host;
                        window.open(`${fullhost}/open.html?dir=${data.directory}&hash=${data.version}&title=${data.file_name}&filetype=${data.extension}&link=${data.url}&id=${user._id}&name=${user.firstname}&type=${docType}`,"_blank");
                    }
                    resolve(result.display_message);
                }else{
                    reject(result.display_message)
                }
            })
            .catch(err => {
                reject(err.response)
            });
        }.bind(this))
    }
    globalFileRetrieve(id,directory){
        return new Promise(function(resolve,reject){
            this.globalGet(`oof/file_retrieve/?id=${id}&directory=${directory}`)
            .then((result)=>{
                if(result.status == 200){
                    var resultData = this.filter(result.data);
                    resolve(resultData[0]);
                }else{
                    reject(result.display_message)
                }
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    moveFolder(data){
        return new Promise(function(resolve,reject){
            this.postJSON(`folder/move`,data)
            .then((result)=>{
                if(result.status == 200){
                    resolve(result.display_message);
                }else{
                    reject(result.display_message)
                }
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    copyFolder(data){
        return new Promise(function(resolve,reject){
            this.postJSON(`folder/copy`,data)
            .then((result)=>{
                if(result.status == 200){
                    resolve(result.display_message);
                }else{
                    reject(result.display_message)
                }
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    uploadFolder(data){
        return new Promise(function(resolve,reject){
            this.postJSON(`folder/upload`,data)
            .then((result)=>{
                if(result.status == 200){
                    resolve(result.display_message);
                }else{
                    reject(result.display_message)
                }
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    downloadMultiple(data){
        return new Promise(function(resolve,reject){
            this.postBuffer(`files/multi_download/`,data)
            .then((result)=>{
                const url = window.URL.createObjectURL(new Blob([result.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "jaybod-download.zip");
                document.body.appendChild(link);
                link.click();
                resolve(true)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
}
