import Client from "./client"
export default class MFA extends Client{
  constructor() {
      super();
  }
  enabledDisabled2fa(data){
    return new Promise(function(resolve,reject){
        this.post('user/2fa/setting', data)
        .then((result)=>{
            if(result.status == 200){
                resolve(result)
            }else{
                reject(result.display_message)
            }
        })
        .catch((err) => {
            reject(err.response)
        })
    }.bind(this))
  }
  verifyCode2fa(data){
    return new Promise(function(resolve,reject){
        this.postLogin('user/2fa/verify/otp', data)
        .then((result)=>{
            if(result.status == 200){
                resolve(result)
            }else{
                reject(result.display_message)
            }
        })
        .catch((err) => {
            reject(err.data.display_message)
        })
    }.bind(this))
  }
  verifyBackupCode2fa(data){
    return new Promise(function(resolve,reject){
        this.postLogin('user/2fa/verify/backupcode', data)
        .then((result)=>{
            if(result.status == 200){
                resolve(result)
            }else{
                reject(result.display_message)
            }
        })
        .catch((err) => {
            reject(err.data.display_message)
        })
    }.bind(this))
  }
  regenerateCode2fa(){
    return new Promise(function(resolve,reject){
        this.get('user/2fa/backupcode/generate')
        .then((result)=>{
            if(result.status == 200){
                resolve(result)
            }else{
                reject(result.display_message)
            }
        })
        .catch((err) => {
            reject(err.response)
        })
    }.bind(this))
  }
  downloadBackupCode2fa(){
    return new Promise(function(resolve,reject){
        this.getBuffer('user/2fa/backupcode/download')
        .then((result)=>{
            const url = window.URL.createObjectURL(new Blob([result.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", 'Backup Codes');
            document.body.appendChild(link);
            link.click();
            resolve(true)
        })
        .catch((err) => {
            reject(err)
        })
    }.bind(this))
  }
  viewBackupCode2fa(password){
    return new Promise(function(resolve,reject){
        this.post('user/2fa/backupcode/view', password)
        .then((result)=>{
            if(result.status == 200){
                resolve(result)
            }else{
                reject(result.display_message)
            }
        })
        .catch((err) => {
            reject(err.response)
        })
    }.bind(this))
  }
}