import Enterprise from '../../api/enterprise'
const enterprise = new Enterprise()

export const state = {
    listEnterprise: [],
    myEnterprise: null,
    overviewEnterprise: null,
    loading: false,
    listSeatEnterprise: [],
    planEnterprise: null,
    switchSubdomain: null,
    enterpriseInfo: null,
    statusSuspend: false
}

export const mutations = {
    SET_LIST_ENTERPRISE(state, payload){
        state.listEnterprise = payload
    },
    SET_MY_ENTERPRISE(state, payload){
        state.myEnterprise = payload
    },
    SET_OVERVIEW_ENTERPRISE(state, payload){
        state.overviewEnterprise = payload
    },
    SET_LOADING(state, newValue){
        state.loading = newValue
    },
    SET_LIST_SEAT_ENTERPRISE(state, payload){
        state.listSeatEnterprise = payload
    },
    SET_PLAN_ENTERPRISE(state, payload){
        state.planEnterprise = payload
    },
    SET_SWITCH_SUBDOMAIN(state, payload){
        state.switchSubdomain = payload
    },
    SET_INFO_ENTERPRISE(state, payload){
        state.enterpriseInfo = payload
    },
    SET_SUSPEND_STATUS(state, value){
        state.statusSuspend = value
    }
}

export const actions = {
    getListEnterprise(context){
        context.commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        return new Promise((resolve, reject) => {
            // let user_id = context.rootGetters["user/id"] //old-version
            let user = context.rootGetters["auth/user"]
            let user_id = ''
            if(user == null){
                user_id = ''
            } else {
                user_id = user[0]._id
            }
            user_id = context.rootGetters["user/id"]
            enterprise.listEnterprise(user_id)
            .then((result)=>{
                context.commit("SET_LOADING",false);
                let newEnterprise = result.map(e => {
                    return {
                        showMore: false,
                        ...e
                    }
                })
                context.commit("SET_LIST_ENTERPRISE", newEnterprise);
                resolve(newEnterprise)
            })
            .catch((err)=>{
                context.commit("error/SET_MESSAGE",err,{root:true});
                context.commit("error/SET_ERROR",true,{root:true});
                reject(err);
            })
        })
    },
    getMyEnterprise(context){
        context.commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        return new Promise((resolve, reject) => {
            enterprise.myEnterprise()
            .then((result) => {
                context.commit("SET_MY_ENTERPRISE", result)
                if(result != null){
                    if(result.suspend != null){
                        var isSuspend = result.suspend.status
                        context.commit("SET_SUSPEND_STATUS", isSuspend)
                    } else {
                        context.commit("SET_SUSPEND_STATUS", false)
                    }
                }
                resolve(result)
            })
            .catch((err)=>{
                context.commit("error/SET_MESSAGE",err,{root:true});
                context.commit("error/SET_ERROR",true,{root:true});
                reject(err);
            })
        })
    },
    getOverviewEnterprise(context){
        context.commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        return new Promise((resolve, reject) => {
            enterprise.overviewEnterprise()
            .then((result) => {
                context.commit("SET_OVERVIEW_ENTERPRISE", result)
                resolve(result)
            })
            .catch((err)=>{
                context.commit("error/SET_MESSAGE",err,{root:true});
                context.commit("error/SET_ERROR",true,{root:true});
                reject(err);
            })
        })
    },
    getListSeatEnterprise(context, payload){
        var currentEnterprise = context.getters['myEnterprise']
        payload.enterprise = currentEnterprise._id

        context.commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        return new Promise((resolve, reject) => {
            enterprise.seatEnterprise(payload)
            .then((result) => {
                context.commit("SET_LIST_SEAT_ENTERPRISE", result)
                resolve(result)
            })
            .catch((err)=>{
                context.commit("error/SET_MESSAGE",err,{root:true});
                context.commit("error/SET_ERROR",true,{root:true});
                reject(err);
            })
        })
    },
    checkSubdomain(context, value){
        context.commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        return new Promise((resolve, reject) => {
            enterprise.subdomainEnterprise(value)
            .then((result) => {
                resolve(result)
            })
            .catch((err)=>{
                context.commit("error/SET_MESSAGE",err,{root:true});
                context.commit("error/SET_ERROR",true,{root:true});
                reject(err);
            })
        })
    },
    getPlanEnterprise(context){
        var currentEnterprise = context.getters['myEnterprise']
        var enterpriseId = currentEnterprise._id
        context.commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        return new Promise((resolve, reject) => {
            enterprise.planEnterprise(enterpriseId)
            .then((result) => {
                context.commit('SET_PLAN_ENTERPRISE', result)
                resolve(result)
            })
            .catch((err)=>{
                context.commit("error/SET_MESSAGE",err,{root:true});
                context.commit("error/SET_ERROR",true,{root:true});
                reject(err);
            })
        })
    },
    getPreviewLogo(context, data){
        // var currentEnterprise = context.getters['myEnterprise']
        // var enterpriseId = currentEnterprise._id
        context.commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        return new Promise((resolve, reject) => {
            enterprise.previewImg(data)
            .then((result) => {
                var picture = result.split('?')
                // context.commit('', result)
                resolve(picture[0])
            })
            .catch((err)=>{
                context.commit("error/SET_MESSAGE",err,{root:true});
                context.commit("error/SET_ERROR",true,{root:true});
                reject(err);
            })
        })
    },
    changeSetting(context, data){
        context.commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        return new Promise((resolve, reject) => {
            enterprise.setSettings(data)
            .then((result) => {
                context.dispatch("getMyEnterprise")
                context.dispatch("user/getListEnterpriseUser", null, {root:true})
                resolve(result)
            })
            .catch((err)=>{
                context.commit("error/SET_MESSAGE",err,{root:true});
                context.commit("error/SET_ERROR",true,{root:true});
                reject(err);
            })
        })
    },
    addSeat(context, data){
        context.commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        return new Promise((resolve, reject) => {
            enterprise.inviteSeat(data)
            .then((result) => {
                var payload = {
                    used: 'ASC',
                    search: ''
                }
                context.dispatch("getListSeatEnterprise", payload)
                context.dispatch("getMyEnterprise")
                resolve(result)
            })
            .catch((err)=>{
                context.commit("error/SET_MESSAGE",err,{root:true});
                context.commit("error/SET_ERROR",true,{root:true});
                reject(err);
            })
        })
    },
    editSeat(context, data){
        context.commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        return new Promise((resolve, reject) => {
            enterprise.seatEdit(data)
            .then((result) => {
                var payload = {
                    used: 'ASC',
                    search: ''
                }
                context.dispatch("getListSeatEnterprise", payload)
                context.dispatch("getMyEnterprise")
                resolve(result)
            })
            .catch((err)=>{
                context.commit("error/SET_MESSAGE",err,{root:true});
                context.commit("error/SET_ERROR",true,{root:true});
                reject(err);
            })
        })
    },
    removeSeat(context, data){
        context.commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        return new Promise((resolve, reject) => {
            enterprise.deleteSeat(data)
            .then((result) => {
                var payload = {
                    used: 'ASC',
                    search: ''
                }
                context.dispatch("getListSeatEnterprise", payload)
                context.dispatch("getMyEnterprise")
                resolve(result)
            })
            .catch((err)=>{
                context.commit("error/SET_MESSAGE",err,{root:true});
                context.commit("error/SET_ERROR",true,{root:true});
                reject(err);
            })
        })
    },
    getToken(context, data){
        context.commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        return new Promise((resolve, reject) => {
            enterprise.generateToken(data)
            .then((result) => {
                resolve(result)
            })
            .catch((err)=>{
                context.commit("error/SET_MESSAGE",err,{root:true});
                context.commit("error/SET_ERROR",true,{root:true});
                reject(err);
            })
        })
    },
    verifyToken(context, data){
        context.commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        return new Promise((resolve, reject) => {
            enterprise.validateToken(data)
            .then((result) => {
                localStorage.setItem('user', JSON.stringify([{token_login:result.token}]))
                context.dispatch("user/getLimit",null,{root:true})
                resolve(result)
            })
            .catch((err)=>{
                context.commit("error/SET_MESSAGE",err,{root:true});
                context.commit("error/SET_ERROR",true,{root:true});
                reject(err);
            })
        })
    },
    getAppSettings(context, data){
        context.commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        return new Promise((resolve, reject) => {
            enterprise.appSettings(data)
            .then((result) => {
                resolve(result)
            })
            .catch((err)=>{
                context.commit("error/SET_MESSAGE",err,{root:true});
                context.commit("error/SET_ERROR",true,{root:true});
                reject(err);
            })
        })
    },
    getSwitchSubdomain(context, data){
        if(data != null){
            var payload = {
                enterprise: data.id,
                link: data.link
            }
            context.commit("SET_SWITCH_SUBDOMAIN", payload)
        } else {
            context.commit("SET_SWITCH_SUBDOMAIN", null)
        }
    },
    getEnterpriseInfo(context, id){
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        return new Promise((resolve, reject) => {
            enterprise.enterpriseInfo(id)
            .then((result) => {
                context.commit("SET_INFO_ENTERPRISE", result.data)
                resolve(result)
            })
            .catch((err)=>{
                context.commit("error/SET_MESSAGE",err,{root:true});
                context.commit("error/SET_ERROR",true,{root:true});
                reject(err);
            })
        })
    }
}

export const getters = {
    statusSuspend(state){
        return state.statusSuspend
    },
    enterpriseInfo(state){
        return state.enterpriseInfo
    },
    switchSubdomain(state){
        return state.switchSubdomain
    },  
    planEnterprise(state){
        return state.planEnterprise
    },
    listSeatEnterprise(state){
        return state.listSeatEnterprise
    },
    listEnterprise(state){
        return state.listEnterprise
    },
    myEnterprise(state){
        return state.myEnterprise
    },
    overviewEnterprise(state){
        return state.overviewEnterprise
    }
}