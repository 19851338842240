
import Client from "./client";
import { SocketAPI } from "./socketio"
export default class User extends Client{
    constructor() {
        super();
    }
    validateHash(hash){
        let data = {hash: hash}
        return new Promise(function(resolve,reject){
            this.postLogin('user/validateMyHash',data)
            .then((result)=>{
                if(result.status == 200){
                    resolve(result.data)
                }else{
                    reject(result.display_message)
                }
                
    // eslint-disable-next-line no-unused-vars
            }).catch(err=>{
            })
        }.bind(this))
    }
    login(email,password){
        var data = {email,password};
        return new Promise(function(resolve,reject){
            this.postLogin('user/singin',data)
            .then((result)=>{
                
                if(result.status == 200){
                    SocketAPI.connect(result.data[0]._id,result.data[0].socket)
                    resolve(result.data);
                }else{
                    if(result.status == 206) {
                        window.open('/confirm-auth-code', '_self') 
                        localStorage.setItem("authUser", JSON.stringify(result.data))
                    }
                    if(result.status == 205) {
                        window.open(result.data.session.url, '_self')
                    }
                    reject(result)
                }
            })
            .catch(err=>{
                reject(err)
            });
        }.bind(this))
    }
    register(data){
        return new Promise(function(resolve,reject){
            this.postLogin('user/singup',data)
            .then((result)=>{
                if(result.status == 200){
                    resolve(result.data);
                }else{
                    reject(result.display_message)
                }
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    verifyCode(data){
        return new Promise(function(resolve,reject){
            this.postLogin('user/activated',data)
            .then((result)=>{
                if(result.status == 200){
                    resolve(result.data);
                }else{
                    reject(result.display_message)
                }
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    tokenDesktop(data){
        return new Promise(function(resolve,reject){
            this.post('token/desktop_token',data)
            .then((result)=>{
                if(result.status == 200){
                    const buffer = new Buffer.from(result.data.token)
                    const tokenBase64Converted = buffer.toString('base64')

                    window.open(`jaybod://login/succes?token=${tokenBase64Converted}`)
                    resolve(result);
                }else{
                    reject(result.display_message)
                }
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    forgotPassword(data){
        return new Promise(function(resolve,reject){
            this.postLogin('user/forgot_password',data)
            .then((result)=>{
                if(result.status == 200){
                    resolve(result.display_message);
                }else{
                    reject(result.display_message)
                }
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    resendCode(data){
        return new Promise(function(resolve,reject){
            this.postLogin('user/send_code',data)
            .then((result)=>{
                if(result.status == 200){
                    resolve(result.display_message);
                }else{
                    reject(result.display_message)
                }
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    userLimit(){
        return new Promise(function(resolve,reject){
            this.get('user/retrieve')
            .then((result)=>{
                if(result.status == 200){
                    resolve(result.data);
                }else{
                    reject(result.display_message)
                }
            })
            .catch(err=> {
                reject(err)
            });
        }.bind(this))
    }
    userListEnterprise(){
        return new Promise(function(resolve,reject){
            this.get('user/enterprise/lists')
            .then((result)=>{
                if(result.status == 200){
                    resolve(result.data);
                }else{
                    reject(result.display_message)
                }
            })
            .catch(err=> {
                reject(err)
            });
        }.bind(this))
    }
    newSocket(id){
        return new Promise(function(resolve,reject){
            this.get('user/renewsocket')
            .then((result)=>{
                if(result.status == 200){
                    SocketAPI.connect(id,result.data.socket)
                    resolve(result.data);
                }else{
                    reject(result.display_message)
                }
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    changePassword(data){
        return new Promise(function(resolve,reject){
            this.putGlobal('user/reset_password',data)
            .then((result)=>{
                if(result.status == 200){
                    resolve(result.display_message);
                }else{
                    reject(result.display_message)
                }
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    editPassword(data){
        return new Promise(function(resolve,reject){
            this.postJSON('user/edit',data)
            .then((result)=>{
                if(result.status == 200){
                    resolve(result.display_message);
                }else{
                    reject(result.display_message)
                }
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    PasswordEdit(data){
        return new Promise(function(resolve,reject){
            this.put('user/edit_password',data)
            .then((result)=>{
                if(result.status == 200){
                    resolve(result.display_message);
                }else{
                    reject(result.display_message)
                }
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    deleteAccount(password){
        return new Promise(function(resolve,reject){
            this.post('user/destroy', password)
            .then((result)=>{
                if(result.status == 200){
                    resolve(result.display_message)
                }else{
                    reject(result.display_message)
                }
            })
            .catch((err) => {
                reject(err.response)
            })
        }.bind(this))
    }
    cancelCurrentPlan(){
        return new Promise(function(resolve,reject){
            this.get('user/cancelPlan')
            .then((result)=>{
                if(result.status == 200){
                    resolve(result.display_message)
                }else{
                    reject(result.display_message)
                }
            })
            .catch((err) => {
                reject(err.response)
            })
        }.bind(this))
    }
}
