import Encrypt from '../../api/encrypt'

const encrypt = new Encrypt()

export const state = {
    loading: false,
    notifEncrypt: [],
    progress: []
}
export const mutations = {
    SET_LOADING(state, newValue) {
        state.loading = newValue;
    },
    ADD_PROGRESS(state, newValue) {
        var index = state.progress.findIndex(v => {
            return v._id == newValue._id
        })

        if(index>= 0){
            var filt = state.progress.splice(index,1)
            state.progress.push(newValue)
        } else {
            state.progress.push(newValue)
        }

    },
    RESET_PROGRES(state) {
        state.progress = []
    },
    UPDATE_PROGRESS(state, newValue) {
        var {_id,encrypt}  = newValue.datafile
        var index = state.progress.findIndex((e)=>e._id==_id)
        state.progress[index].encrypt = encrypt
    },
    SET_NOTIF_ENCRYPT(state,payload){
        state.notifEncrypt.push(payload)
    },
    REMOVE(state,index){
        var indexFile = state.progress.findIndex(v => {
            return v._id == index
        })
        state.progress.splice(indexFile,1)  
    },
    CLEAR(state){
        state.notifEncrypt = []
    }
}
export const actions = {
    setEncrypt(context,payload){
        context.commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        let currentState = context.rootGetters['state/state']
        if(currentState == 'workgroup'){
            let workgroup = context.rootGetters['workgroup/workgroup']
            if(workgroup.enterprise != null){
                payload.enterpriseName = workgroup.enterprise.company_name
            }
            payload.workgroupId = workgroup._id
        }
        return new Promise((resolve, reject) => {
            encrypt.encryptFile(payload)
            .then((result)=>{
                context.commit("SET_LOADING",false);
                resolve(result)
            })
            .catch((err)=>{
                context.commit("error/SET_MESSAGE",err,{root:true});
                context.commit("error/SET_ERROR",true,{root:true});
                reject(err);
            })
        })
    },
    setNotifEncrypt(context,data){
        context.commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});

        var dataEncrypt = {
            id: data.body.data._id,
            file_name: data.body.data.file_name,
            encrypt: data.body.data.encrypt.code,
            time: data.body.time
        }
        context.commit("SET_NOTIF_ENCRYPT", dataEncrypt)
        context.commit("SET_LOADING",false);
    },
}
export const getters = {
    notifEncrypt(state){
        return state.notifEncrypt
    },
    progress(state){
        return state.progress
    }
}