import Client from "./client"

export default class Package extends Client{
    constructor() {
        super();
    }
    allNotification(user_id){
        return new Promise(function(resolve,reject){
            this.get(`notifs?userid=${user_id}`)
            .then((result)=>{
                if(result.status == 200){
                    resolve(result.data);
                }else{
                    reject(result.display_message)
                }
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    readNotification(notif_id){
        return new Promise(function(resolve,reject){
            this.put(`notifs/${notif_id}`)
            .then((result)=>{
                if(result.status == 200){
                    resolve(result.data);
                }else{
                    reject(result.display_message)
                }
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
}