<template>
  <div>
    <Navbar class="nav-z-idx"/>
    <div class="container-v3">
      <div class="center-content-v3">
        <div class="maintenance-v3">
          <img src="@/assets/images/maintenance.svg" alt="">
          <p class="title-maintenance-v3">Page not found</p>
          <p class="text-maintenance-v3 text-center mb-4">Sorry, we can’t find the page you’re looking for.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "../../../components/nav-login-registerV3.vue";
import appConfig from "@/app.config";

export default {
  page: {
    title: "404",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    Navbar
  }
}
</script>

<style>

</style>
