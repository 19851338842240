import Client from "../api/client";

export default class Decrypt extends Client {
    constructor() {
        super();
    }
    decryptFile(payload){
        return new Promise(function(resolve,reject){
            this.post(`encrypt/decrypt`, payload)
            .then((result)=>{
                if(result.status == 200){
                    resolve(result);
                }else{
                    reject(result.display_message)
                }
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
}