import Client from "./client";
export default class Share extends Client{
    constructor() {
        super();
    }
    editAccess(data){
        return new Promise(function(resolve,reject){
            this.put(`files/share`,data)
            .then((result)=>{
                if(result.status == 200){
                    resolve(result.display_message);   
                }else{
                    reject(result.display_message)
                }
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    remove_user(data){
        return new Promise(function(resolve,reject){
            this.postJSON(`files/delete_share`,data)
            .then((result)=>{
                if(result.status == 200){
                    resolve(result.display_message);   
                }else{
                    reject(result.display_message)
                }
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    add_user(data){
        return new Promise(function(resolve,reject){
            this.postJSON(`files/share`,data)
            .then((result)=>{
                if(result.status == 200){
                    resolve(result.display_message);   
                }else{
                    reject(result.display_message)
                }
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    listing(limit,offset,keyword){
        return new Promise(function(resolve,reject){
            this.get(`files/share?limit=${limit}&offset=${offset}&keyword=${keyword}`)
            .then((result)=>{
                if(result.status == 200){
                    resolve(result.data); 
                }else{
                    reject(result.display_message)
                }
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    public_share(data){
        return new Promise(function(resolve,reject){
            this.postJSON(`files/enable_share`,data)
            .then((result)=>{
                if(result.status == 200){
                    resolve(result.display_message);   
                }else{
                    reject(result.display_message)
                }
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    disable_public_share(data){
        return new Promise(function(resolve,reject){
            this.postJSON(`files/disable_share`,data)
            .then((result)=>{
                if(result.status == 200){
                    resolve(result.display_message);   
                }else{
                    reject(result.display_message)
                }
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
}
