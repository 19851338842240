export const state = {
    state : "storage",
}

export const mutations = {
    SET_STATE(state, newValue) {
        state.state = newValue
    },
}

export const getters = {
    state(state) {
        return state.state
    }
}

