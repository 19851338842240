// eslint-disable-next-line no-unused-vars
const axios = require('axios');
// import router from "@/router";
const qs = require('querystring')

import store from '../state/store';
import getNameSubDomain from '../utils/getNameSubDomain';

export default class Client{
    constructor(){
        this._BASEURL = process.env.VUE_APP_API;
    }
    getToken(){
        return localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user'))[0].token_login : ""
    }
    getActivityToken(){
        let token = this.getToken()
        let config = {
            headers: {
                // 'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization':"Bearer "+token,
                "Access-Control-Allow-Origin": "*",
                "x-agent" : navigator.userAgent,
                "x-platform" : navigator.platform,
                "x-domain" : getNameSubDomain()
            },
            crossdomain: true,
            useCredentails : true
        }
        let data = {}
        return new Promise(function(resolve,reject){
            axios.post(`${this._BASEURL}token`,data,config)
            .then(function (result) {
                    var response = result.data
                    if(response.status == '200'){
                        resolve(response.data.token);
                    }else if(response.status == '205'){
                        store.dispatch('user/setSuspend', true)
                        store.dispatch('user/setInfoSuspend', response.data)
                        store.dispatch('user/setMessageSuspend', response.message)
                        store.dispatch('user/setSubMessageSuspend', response.display_message)
                        reject(response.status)
                    } else {
                        reject(response.display_message)
                    }
                })
            .catch(function (error) {
                reject(error.response)
            });
        }.bind(this));
    }
    testing(){
        return this.getActivityToken().then((r)=>r);
    }
    getBaseUrl(){
        return this._BASEURL;
    }
    setBaseUrl(url){
        this._BASEURL=url;
    }// eslint-disable-next-line no-unused-vars
    getAuthConfig(type){
        return new Promise(function(resolve,reject){
            this.getActivityToken().then((token)=>{
                let config = {
                    headers: {
                        // 'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization':"Bearer "+token,
                        "Access-Control-Allow-Origin": "*",
                        "x-agent" : navigator.userAgent,
                        "x-platform" : navigator.platform,
                        "x-domain" : getNameSubDomain()
                    },
                    crossdomain: true,
                    useCredentails : true
                }
                resolve(config)
            }).catch((error)=>{
                reject(error)
            })
        }.bind(this));
    }
    header(){
        let token = this.getToken();
        return {
            'Authorization':"Bearer "+token,
            "Content-Type":"multipart/form-data",
            "Access-Control-Allow-Origin": "*"
        }
    }
    /**
     * @param {string} endpoint The string
    */
   
    getBuffer(endpoint){
        return new Promise(function(resolve,reject){
            this.getActivityToken().then((token)=>{
                var header =  {
                    'Authorization':"Bearer "+token,
                    "x-agent" : navigator.userAgent,
                    "x-platform" : navigator.platform,
                    "x-domain" : getNameSubDomain()
                }
                var config = {
                    method: 'get',
                    responseType: "arraybuffer",
                    url: `${this._BASEURL}${endpoint}`,
                    headers: header
                };
                axios(config)
                .then(function (response) {
                    resolve(response);
                })
                .catch(function (error) {
                    reject(error)
                });
            }).catch((error)=>{
                reject(error)
            })
        }.bind(this));
    }
    
    postBuffer(endpoint,data){
        return new Promise(function(resolve,reject){
            this.getActivityToken().then((token)=>{
                var header =  {
                    'Authorization':"Bearer "+token,
                    "Content-Type":"application/json",
                    "x-agent" : navigator.userAgent,
                    "x-platform" : navigator.platform,
                    "x-domain" : getNameSubDomain()
                }
                var config = {
                    method: 'post',
                    responseType: "arraybuffer",
                    url: `${this._BASEURL}${endpoint}`,
                    headers: header,
                    data : JSON.stringify(data)
                };
                axios(config)
                .then(function (response) {
                    resolve(response);
                })
                .catch(function (error) {
                    reject(error)
                });
            }).catch((error)=>{
                reject(error)
            })
        }.bind(this));
    }

    get(endpoint){
        return new Promise(function(resolve,reject){
            this.getAuthConfig("").then((config)=>{
                axios.get(`${this._BASEURL}${endpoint}`,config)
                .then(function (result) {
                        resolve(result.data);
                    })
                .catch(function (error) {
                        // if(error.response.status  === 401){
                            // router.push('/logout')
                        // }else if(error.response.status === 400){
                            reject(error)
                        // }
                    });
            }).catch((error)=>{
                reject(error)
            })
        }.bind(this));
    }
    globalGet(endpoint){
        return new Promise(function(resolve,reject){
            axios.get(`${this._BASEURL}${endpoint}`)
            .then(function (result) {
                    resolve(result.data);
                })
            .catch(function (error) {
                        reject(error)
                });
        }.bind(this));
    }



    /**
     * @param {string} endpoint The string
     * @param {object} data data
    */
    post(endpoint,data){
        return new Promise(function(resolve,reject){
            this.getAuthConfig("").then((config)=>{
                axios.post(`${this._BASEURL}${endpoint}`,qs.stringify(data),config)
                .then(function (result) {
                        resolve(result.data);
                    })
                .catch(function (error) {
                    // if(error.response.status  === 401){
                        // router.push('/logout')
                    // }else if(error.response.status === 400){ 
                    reject(error)

                    // }
                    });
            }).catch((error)=>{ 
                reject(error)
            })
        }.bind(this));
    }
    /**
     * @param {string} endpoint The string
     * @param {object} data data
    */
    postJSON(endpoint,data){
        return new Promise(function(resolve,reject){
            this.getActivityToken().then((token)=>{
                var header =  {
                    'Authorization':"Bearer "+token,
                    "Content-Type":"application/json",
                    "Access-Control-Allow-Origin": "*",
                    "x-agent" : navigator.userAgent,
                    "x-platform" : navigator.platform,
                    "x-domain" : getNameSubDomain()
                }
                var config = {
                    method: 'post',
                    url: `${this._BASEURL}${endpoint}`,
                    headers: header,
                    data : JSON.stringify(data),
                    crossdomain: true,
                    useCredentails : true
                };
                axios(config)
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    reject(error)
                });

            }).catch((error)=>{
                reject(error)
            })
        }.bind(this));
    }

    

    /**
     * @param {string} endpoint The string
     * @param {object} data data
    */
    postLogin(endpoint,data){
        let config = {
            headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
                "x-agent" : navigator.userAgent,
                "x-platform" : navigator.platform,
                "x-domain" : getNameSubDomain()
            },
            crossdomain: true,
            useCredentails : true
        }
        return new Promise(function(resolve,reject){
            axios.post(`${this._BASEURL}${endpoint}`,data,config)
            .then(function (result) {
                    resolve(result.data);
                })
            .catch(function (err) {
                    return reject(err.response);
                });
        }.bind(this));
    }
    
    /**
     * @param {string} endpoint The string
     * @param {object} data data
    */
     putGlobal(endpoint,data){
        let config = {
            headers: {
                'Content-Type': 'application/json',
            }
        }
        return new Promise(function(resolve,reject){
            axios.put(`${this._BASEURL}${endpoint}`,data,config)
            .then(function (result) {
                    resolve(result.data);
                })
            .catch(function (err) {
                    return reject(err);
                });
        }.bind(this));
    }


    /**
     * @param {string} endpoint The string
     * @param {object} data data
    */
   put(endpoint,data){
        return new Promise(function(resolve,reject){
            this.getAuthConfig("").then((config)=>{
                axios.put(`${this._BASEURL}${endpoint}`,data,config)
                .then(function (result) {
                        resolve(result.data);
                    })
                .catch(function (error) {
                    // if(error.response.status  === 401){
                        // router.push('/logout')
                    // }else if(error.response.status === 400){
                        reject(error)
                    // }
                    });
            }).catch((error)=>{
                reject(error)
            })
        }.bind(this));
    }

    /**
     * @param {string} endpoint The string
    */
   delete(endpoint,data){
        return new Promise(function(resolve,reject){
            this.getActivityToken().then((token)=>{
                var header =  {
                    'Authorization':"Bearer "+token,
                    "Content-Type":"application/json",
                    "x-agent" : navigator.userAgent,
                    "x-platform" : navigator.platform,
                    "x-domain" : getNameSubDomain()
                }
                var config = {
                    method: 'delete',
                    url: `${this._BASEURL}${endpoint}`,
                    headers: header,
                    data : data
                };
                axios(config)
                .then(function (result) {
                    resolve(result.data);
                })
                .catch(function (error) {
                    reject(error)
                });
            }).catch((error)=>{
                reject(error)
            })
        }.bind(this));
    }

    
    /**
     * @param {string} endpoint The string
    */
   hapus(endpoint){
        return new Promise(function(resolve,reject){
            this.getAuthConfig("").then((config)=>{
                axios.delete(`${this._BASEURL}${endpoint}`,config)
                .then(function (result) {
                        resolve(result.data);
                    })
                .catch(function (error) {
                    reject(error.response)
                    });
            }).catch((error)=>{
                reject(error)
            })
        }.bind(this));
    }

    /**
     * @param {string} endpoint The string
     * @param {object} data data
    */
    postFormdata(endpoint,data){
        return new Promise(function(resolve,reject){
            this.getActivityToken().then((token)=>{
                var header =  {
                    'Authorization':"Bearer "+token,
                    "Content-Type":"multipart/form-data",
                    "Access-Control-Allow-Origin": "*",
                    "x-agent" : navigator.userAgent,
                    "x-platform" : navigator.platform,
                    "x-domain" : getNameSubDomain()
                }
                axios.post(`${this._BASEURL}${endpoint}`,data,{headers:header})
                .then(function (result) {
                    resolve(result.data);
                })
                .catch(function (err) {
                        reject(err);
                    });
            }).catch((error)=>{
                reject(error);
            })
        }.bind(this));
    }
    putFormdata(endpoint,data){
        return new Promise(function(resolve,reject){
            this.getActivityToken().then((token)=>{
                var header =  {
                    'Authorization':"Bearer "+token,
                    "Content-Type":"multipart/form-data",
                    "Access-Control-Allow-Origin": "*",
                    "x-agent" : navigator.userAgent,
                    "x-platform" : navigator.platform,
                    "x-domain" : getNameSubDomain()
                }
                axios.put(`${this._BASEURL}${endpoint}`,data,{headers:header})
                .then(function (result) {
                        resolve(result.data);
                    })
                .catch(function (err) {
                        reject(err);
                    });
            }).catch((error)=>{
                reject(error);
            })
        }.bind(this));
    }

}

//module.exports = Client;
